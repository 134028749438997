import { Contact } from '@shared/models/contact';
import { Rs911State } from '@shared/models/store/rs911State';
import rs911Service from '@shared/services/rs911-service';

export const rs911 = {
  namespaced: true,
  state: {
    callerContacts: [],
    callId: ''
  },
  actions: {
    async getUser() {
      const res = await rs911Service.getUser();
      return Promise.resolve(res);
    },
    async isFirstInstall() {
      const res = await rs911Service.isFirstInstall();
      return Promise.resolve(res);
    },
    async updateUser({ commit }: any, user: any) {
      try {
        const res = await rs911Service.updateUser(user);
        return Promise.resolve(res);
      } catch(err: any) {
        return Promise.reject(err.response);
      }
    },
    async searchFr({ commit }: any, data: {orgid: number; keyword: string}) {
      const res = await rs911Service.searchFr(data?.orgid, data?.keyword);
      return Promise.resolve(res);
    },
    async getAlert({ commit }: any, id: string) {
      const res = await rs911Service.getAlert(id);
      return Promise.resolve(res);
    },
    async getLocation({ commit }: any, id: string) {
      const res = await rs911Service.getLocation(id);
      return Promise.resolve(res);
    },
    async getContacts({ commit }: any, id: string) {
      const res = await rs911Service.getContacts(id);
      commit('updateContacts', res.data);
      return Promise.resolve(res);
    },
    async getMedinfo({ commit }: any, id: string) {
      const res = await rs911Service.getMedinfo(id);
      return Promise.resolve(res);
    },
    async getVideoToken({ commit }: any, id: string) {
      const res = await rs911Service.getVideoToken(id);
      return Promise.resolve(res);
    },
    async invite({ commit }: any, id: { users: any; callid: string}) {
      const res = await rs911Service.invite(id);
      commit('inviteContact', id);
      return Promise.resolve(res);
    },
    async qrcode() {
      const res = await rs911Service.qrcode();
      return Promise.resolve(res);
    }
  },
  mutations: {
    updateContacts(state: Rs911State, contacts: Contact[]) {
      state.callerContacts = contacts.map(x => {
        return {
          ...x,
          invited: false
        };
      });
    },
    inviteContact(state: Rs911State, id: { users: { ssoid: string }[]; callid: string}) {
      const contacts = state.callerContacts.filter(x => id.users.some(y => y.ssoid === x.ssoid));
      contacts.forEach(x => {
        x.invited = true;
      });
    },
    updateCallId(state: Rs911State, callId: string) {
      state.callId = callId;
    },
    clearCallId(state: Rs911State) {
      state.callId = '';
    }

  }
};