import { _axios } from '@shared/plugins/axios';
const API_URL = process.env.VUE_APP_MEDINFO_URL;

class PhysicianService {
  getUserPhysicians() {
    return _axios.get(API_URL + '/users/physicians');
  }

  getUserPhysician(id: number) {
    return _axios.get(API_URL + '/users/physicians/' + id);
  }

  addUserPhysician(physicain: any) {
    return _axios.post(API_URL + '/users/physicians', physicain);
  }

  editUserPhysician(id: number, physician: { fullName: string; specialty: string; phoneNumber?: string; id?: number}) {
    return _axios.put(API_URL + '/users/physicians/' + id, physician);
  }

  deleteUserPhysician(id: number) {
    return _axios.delete(API_URL + '/users/physicians/' + id);
  }

  searchPhysicians(number: string) {
    return _axios.get(API_URL + '/physicians/search?searchString=' + number);
  }
}

export default new PhysicianService();