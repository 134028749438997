import { _axios } from '../plugins/axios';
import { Location } from '@shared/models/location';
const API_URL = process.env.VUE_APP_RS911_URL;

class LocationService {
  getAddresses() {
    return _axios.post(API_URL + '/address/list');
  }

  createLocation(location: Location) {
    return _axios.post(API_URL + '/address/add', location);
  }

  updateLocatoin(location: Location) {
    return _axios.post(API_URL + '/address/update', location);
  }

  deleteLocation(locationSlot: number) {
    return _axios.post(API_URL + '/address/delete', {slot: locationSlot});
  }
}

export default new LocationService();