/* eslint-disable @typescript-eslint/no-explicit-any */
import userService from '@shared/services/medInfo/user-service';
import { allergy } from './allergy.module';
import { physician } from './physician.module';
import { diagnose } from './diagnose.module';
import { medication } from './medication.module';

export const medInfo = {
  namespaced: true,
  state: {

  },
  actions: {
    async getUser() {
      const res = await userService.getUser();
      return Promise.resolve(res);
    },
    async getUserMedicalInfo() {
      const res = await userService.getUserMedicalInfo();
      return Promise.resolve(res);
    },
    async createUser(commit: any, item: any) {
      const res = await userService.createUser(item);
      return Promise.resolve(res);
    },
    async editUser(commit: any, item: any) {
      const res = await userService.editUser(item);
      return Promise.resolve(res);
    },
    async getQrCode(commit: any, size: number) {
      const res = await userService.getQrCode(size);
      return Promise.resolve(res);
    },
    async getInfoWithSecret(commit: any, secret: string) {
      const res = await userService.getInfoWithSecret(secret);
      return Promise.resolve(res);
    }

  },
  mutations: {
    
  },
  modules: {
    allergy,
    physician,
    diagnose,
    medication
  }
};