/* eslint-disable @typescript-eslint/no-explicit-any */
import locationService from '@shared/services/location-service';
import { Location } from '@shared/models/location';

export const location = {
  namespaced: true,
  state: {

  },
  actions: {
    async getAll() {
      const res = await locationService.getAddresses();
      return Promise.resolve(res);
    },
    async create(commit: any, location: Location) {
      const res = await locationService.createLocation(location);
      return Promise.resolve(res);
    },
    async update(commit: any, location: Location) {
      const res = await locationService.updateLocatoin(location);
      return Promise.resolve(res);
    },
    async delete(commit: any, locationSlot: number) {
      const res = await locationService.deleteLocation(locationSlot);
      return Promise.resolve(res);
    }
  },
  mutations: {
    
  }
};