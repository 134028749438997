/* eslint-disable @typescript-eslint/no-explicit-any */
import contactService from '@shared/services/contact-service';
import { Contact } from '@shared/models/contact';

export const contact = {
  namespaced: true,
  state: {

  },
  actions: {
    async getAll() {
      const res = await contactService.getContacts();
      return Promise.resolve(res);
    },
    async create(commit: any, contact: Contact) {
      const res = await contactService.createContact(contact);
      return Promise.resolve(res);
    },
    async update(commit: any, contact: Contact) {
      const res = await contactService.updateContact(contact);
      return Promise.resolve(res);
    },
    async delete(commit: any, id: number) {
      const res = await contactService.deleteContact(id);
      return Promise.resolve(res);
    },
    async getAllFavorite() {
      const res = await contactService.getFavoriteContacts();
      return Promise.resolve(res);
    },
    async createFavorite(commit: any, contact: Contact) {
      const res = await contactService.createFavoriteContact(contact);
      return Promise.resolve(res);
    },
    async updateFavorite(commit: any, contact: Contact) {
      const res = await contactService.updateFavoriteContact(contact);
      return Promise.resolve(res);
    },
    async deleteFavorite(commit: any, id: number) {
      const res = await contactService.deleteFavoriteContact(id);
      return Promise.resolve(res);
    },
    async qrCode(commit: any, secret: string) {
      const res = await contactService.qrCode(secret);
      return Promise.resolve(res);
    }
  },
  mutations: {
    
  }
};