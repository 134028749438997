/* eslint-disable @typescript-eslint/no-explicit-any */
import medicationService from '@shared/services/medInfo/medication-service';

export const medication = {
  namespaced: true,
  state: {

  },
  actions: {
    async getUserMedications() {
      const res = await medicationService.getUserMedications();
      return Promise.resolve(res);
    },
    async addUserMedication(commit: any, item: any) {
      const res = await medicationService.addUserMedication(item);
      return Promise.resolve(res);
    },
    async deleteUserMedication(commit: any, id: number) {
      const res = await medicationService.deleteUserMedication(id);
      return Promise.resolve(res);
    },
    async editUserMedication(commit: any, item: { name: string; userPhysicianId: number; dosage: string; id: number}) {
      const res = await medicationService.editUserMedication(item.id, {
        name: item.name,
        userPhysicianId: item.userPhysicianId,
        dosage: item.dosage
      });
      return Promise.resolve(res);
    }
  },
  mutations: {
    
  },
  modules: {
    
  }
};