/* eslint-disable @typescript-eslint/no-explicit-any */
import allergyService from '@shared/services/medInfo/allergy-service';
import physicianService from '@shared/services/medInfo/physician-service';
import { Allergy } from '@shared/models/allergy';

export const physician = {
  namespaced: true,
  state: {

  },
  actions: {
    async getUserPhysicians() {
      const res = await physicianService.getUserPhysicians();
      return Promise.resolve(res);
    },
    async getUserPhysician(commit: any, id: number) {
      const res = await physicianService.getUserPhysician(id);
      return Promise.resolve(res);
    },
    async addUserPhysician(commit: any, physician: any) {
      const res = await physicianService.addUserPhysician(physician);
      return Promise.resolve(res);
    },

    async deleteUserPhysician(commit: any, id: number) {
      const res = await physicianService.deleteUserPhysician(id);
      return Promise.resolve(res);
    },

    async searchPhysicians(commit: any, number: string) {
      const res = await physicianService.searchPhysicians(number);
      return Promise.resolve(res);
    },


    async editUserPhysician(commit: any, physician: { fullName: string; specialty: string; phoneNumber: string; id: number }) {
      const res = await physicianService.editUserPhysician(physician.id, {
        fullName: physician.fullName,
        specialty: physician.specialty,
        phoneNumber: physician.phoneNumber,
        // id: physician.id 
      });
      return Promise.resolve(res);
    },

    async editExistingUserPhysician(commit: any, physician: { fullName: string; specialty: string; phoneNumber: string; id: number }) {
      const res = await physicianService.editUserPhysician(physician.id, {
        fullName: physician.fullName,
        specialty: physician.specialty,
        phoneNumber: physician.phoneNumber,
        id: physician.id 
      });
      return Promise.resolve(res);
    }
  },
  mutations: {
    
  },
  modules: {
    
  }
};