import { _axios } from '@shared/plugins/axios';
const API_URL = process.env.VUE_APP_MEDINFO_URL;

class UserService {
  getUser() {
    return _axios.get(API_URL + '/users');
  }

  getUserMedicalInfo() {
    return _axios.get(API_URL + '/users/medicalInfo');
  }

  createUser(item: any) {
    return _axios.post(API_URL + '/users', item);
  }

  editUser(item: any) {
    return _axios.put(API_URL + '/users', item);
  }

  getQrCode(size: number) {
    return _axios.get(API_URL + '/users/medicalInfo/qrCode?size=' + size);
  }

  getInfoWithSecret(secret: string) {
    return _axios.get(API_URL + '/users/medicalInfo/qrCode/' + secret);
  }
}

export default new UserService();