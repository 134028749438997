/* eslint-disable @typescript-eslint/no-explicit-any */
import couponService from '@shared/services/coupon-service';
import { Coupon } from '@shared/models/coupon';
import { Subs } from '@shared/models/api/subs';
import { CouponState } from '@shared/models/store/couponState';
import { CreateSession } from '@shared/models/api/request/CreateSession';
import { AxiosResponse } from 'axios';

export const coupon = {
  namespaced: true,
  state: {
    frCoupon: null,
    firstResponder: false,
    standardUser: false,
    contact: false
  },
  actions: {
    async getAll(): Promise<AxiosResponse<any>> {
      const res = await couponService.getAllCoupons();
      return Promise.resolve(res);
    },
    async getAllSecondPartner({ commit }: any) {
      const res = await couponService.getAllSecondPartnerCoupons();
      commit('couponsUpdate', res.data);
      return Promise.resolve(res);
    },
    async getAllSecondPartnerCouponsById(commit: any, id: number) {
      const res = await couponService.getAllSecondPartnerCouponsById(id);
      return Promise.resolve(res);
    },
    async create(commit: any, coupon: Coupon) {
      try {
        const response = await couponService.createCoupon(coupon);
        return Promise.resolve(response.data);
      } catch (error: any) {
        return Promise.reject(error.response);
      }
    },
    async update(commit: any, coupon: Coupon) {
      try {
        const response = await await couponService.updateCoupon(coupon);
        return Promise.resolve(response.data);
      } catch (error: any) {
        return Promise.reject(error.response);
      }
    },
    async delete({ commit }: any, coupon: Coupon) {
      const res = await couponService.deleteCoupon(coupon);
      return Promise.resolve(res);
    },
    async verifyCouponCode({ commit }: any, couponCode: string) {
      const res = await couponService.verifyCouponCode(couponCode);
      return Promise.resolve(res);
    },
    async getSubscriptions({ commit }: any) {
      const res = await couponService.getSubscriptions();
      commit('setActiveSubscriptions', res.data);
      return res;
    },
    async checkSubscription({ commit }: any, featureName: string) {
      const res = await couponService.checkSubscription(featureName);
      return Promise.resolve(res);
    },
    async getProducts({ commit }: any) {
      const res = await couponService.getProducts();
      return Promise.resolve(res);
    },
    async checkoutSession({ commit }: any, data: CreateSession) {
      const res = await couponService.checkoutSession(data);
      return Promise.resolve(res);
    },
    async customerPortalSession({ commit }: any, returnUrl: string) {
      const res = await couponService.customerPortalSession(returnUrl);
      return Promise.resolve(res);
    },
    clearCouponRoles({ commit }: any) {
      commit('clearCouponRoles');
    }
  },
  mutations: {
    couponsUpdate(state: CouponState, coupons: Coupon[]) {
      const getFrCoupon = coupons.find((x: { featureName: string }) => x.featureName === '911.first_responder');
      state.frCoupon = getFrCoupon;
    },
    clearCouponRoles(state: CouponState) {
      state.firstResponder = false;
      state.standardUser = false;
      state.contact = false;
    },
    setActiveSubscriptions(state: CouponState, subs: Subs[]) {
      console.log('Active subs', subs);
      subs.filter(x => x.active).forEach(x => {
        switch(x.featureName) {
          case '911.first_responder':
            state.firstResponder = true;
            break;
          case '911.standard_user':
            state.standardUser = true;
            break;
          case '911.contact':
            state.contact = true;
        }
      });
    }
  },
  getters: {

  }
};