/* eslint-disable @typescript-eslint/no-explicit-any */
import diagnoseService from '@shared/services/medInfo/diagnose-service';
import { Allergy } from '@shared/models/allergy';

export const diagnose = {
  namespaced: true,
  state: {

  },
  actions: {
    async getUserDiagnoses() {
      const res = await diagnoseService.getUserDiagnoses();
      return Promise.resolve(res);
    },
    async addUserDiagnose(commit: any, diagnose: any) {
      const res = await diagnoseService.addUserDiagnose(diagnose);
      return Promise.resolve(res);
    },
    async deleteUserDiagnose(commit: any, id: number) {
      const res = await diagnoseService.deleteUserDiagnose(id);
      return Promise.resolve(res);
    },
    async editUserDiagnose(commit: any, diagnose: { name: string; userPhysicianId: number; id: number}) {
      const res = await diagnoseService.editUserDiagnose(diagnose.id, {
        name: diagnose.name,
        userPhysicianId: diagnose.userPhysicianId,
      });
      return Promise.resolve(res);
    }
  },
  mutations: {
    
  },
  modules: {
    
  }
};