
import { _axios } from '@shared/plugins/axios';
import { Allergy } from '@shared/models/allergy';
const API_URL = process.env.VUE_APP_MEDINFO_URL;

class AllergyService {
  getUserAllergies() {
    return _axios.get(API_URL + '/users/allergies');
  }

  getUserAllergy(id: number) {
    return _axios.get(API_URL + '/users/allergies/' + id);
  }

  searchAllergies(key: string) {
    return _axios.get(API_URL + '/allergies/search?searchString=' + key);
  }

  createUserAllergy(allergy: Allergy) {
    return _axios.post(API_URL + '/users/allergies', allergy);
  }

  editUserAllergy(id: number, allergy: { name: string; severity: string; epiPenRequired: boolean }) {
    return _axios.put(API_URL + '/users/allergies/' + id, allergy);
  }

  deleteUserAllergy(id: number) {
    return _axios.delete(API_URL + '/users/allergies/' + id);
  }
}

export default new AllergyService();