import axios from 'axios';
import { _axios } from '@shared/plugins/axios';
// import vueApp from vueAppImport;
import qs from 'qs';
const API_URL = process.env.VUE_APP_SSO_URL;
const CLIENT_ID = process.env.VUE_APP_SSO_CLIENT_ID;
const CLIENT_SECRET = process.env.VUE_APP_SSO_CLIENT_SECRET;
const SCOPE = process.env.VUE_APP_SSO_SCOPE;
const config = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
};

class AuthService {
  async login(username: string, password: string ) {
    const data = {
      grant_type: 'password',
      username: username,
      password: password,
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      scope: SCOPE
    };
    const response = await _axios.post(API_URL + '/connect/token', qs.stringify(data), config);
    localStorage.setItem('access-token', JSON.stringify(response.data.access_token));
    localStorage.setItem('refresh-token', JSON.stringify(response.data.refresh_token));
    return response.data;
  }

  async appleLogin(token: string) {
    const data = {
      grant_type: 'external_id_token',
      provider: 'apple',
      token: token,
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      scope: SCOPE
    };
    const response = await _axios.post(API_URL + '/connect/token', qs.stringify(data), config);
    localStorage.setItem('access-token', JSON.stringify(response.data.access_token));
    localStorage.setItem('refresh-token', JSON.stringify(response.data.refresh_token));
    return response.data;
  }

  async googleLogin(token: string) {
    const data = {
      grant_type: 'external_id_token',
      provider: 'google',
      token: token,
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      scope: SCOPE
    };
    const response = await _axios.post(API_URL + '/connect/token', qs.stringify(data), config);
    localStorage.setItem('access-token', JSON.stringify(response.data.access_token));
    localStorage.setItem('refresh-token', JSON.stringify(response.data.refresh_token));
    return response.data;
  }

  async refreshToken() {
    const data = {
      grant_type: 'refresh_token',
      refresh_token: JSON.parse(localStorage.getItem('refresh-token') || '{}'),
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
    };
    const response = await _axios.post(API_URL + '/connect/token', qs.stringify(data), config);
    return response.data;
  }


  userInfo() {
    return _axios.get(API_URL + '/connect/userinfo');
  }

  getUserSecondPartner() {
    return axios.get(API_URL + '/users/secondPartner');
  }

  register(user: { username: string; email: string; password: string }) {
    return axios.post(API_URL + '/users', qs.stringify(user), config);
  }

  requestPasswordReset(email: string) {
    return axios.post(API_URL + '/users/password/reset', qs.stringify({email: email}), config);
  }

  createPassword(data: { email: string; token: string; password: string}) {
    return axios.post(API_URL + '/users/password/reset/create', qs.stringify(data), config);
  }
  

  registerSecondPartner(user: any) {
    return axios.post(API_URL + '/secondPartners', qs.stringify(user), config);
  }

  confirmSecondPartnerMail(data: { UserId: string; Token: string }) {
    return axios.post(API_URL + '/secondPartners/email/confirm', qs.stringify(data), config);
  }

  getSecondPartner() {
    return _axios.get(API_URL + '/secondPartners');
  }

  searchSecondPartners(searchString: string) {
    return _axios.get(API_URL + '/secondPartners/search?searchString=' + searchString);
  }

  updateSecondPartner(user: any) {
    return _axios.put(API_URL + '/secondPartners', qs.stringify(user), config);
  }

  getSecondPartnerAdmins() {
    return _axios.get(API_URL + '/secondPartners/admins');
  }

  grantAdmin(data: {email: string; firstName: string; lastName: string }) {
    return _axios.post(API_URL + '/secondPartners/admins/grant', qs.stringify(data));
  }

  revokeAdmin(data: { email: string  }) {
    return _axios.post(API_URL + '/secondPartners/admins/revoke', qs.stringify(data));
  }
}

export default new AuthService();