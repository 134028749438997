/* eslint-disable @typescript-eslint/no-explicit-any */
import allergyService from '@shared/services/medInfo/allergy-service';
import { Allergy } from '@shared/models/allergy';

export const allergy = {
  namespaced: true,
  state: {

  },
  actions: {
    async getUserAllergies() {
      const res = await allergyService.getUserAllergies();
      return Promise.resolve(res);
    },
    async searchAllergies(commit: any, key: string) {
      const res = await allergyService.searchAllergies(key);
      return Promise.resolve(res);
    },
    async createUserAllergy(commit: any, allergy: any) {
      const res = await allergyService.createUserAllergy(allergy);
      return Promise.resolve(res);
    },
    async deleteUserAllergy(commit: any, id: number) {
      const res = await allergyService.deleteUserAllergy(id);
      return Promise.resolve(res);
    },
    async editUserAllergy(commit: any, allergy: { name: string; severity: string; epiPenRequired: boolean; id: number }) {
      const res = await allergyService.editUserAllergy(allergy.id, {
        name: allergy.name,
        severity: allergy.severity,
        epiPenRequired: allergy.epiPenRequired 
      });
      return Promise.resolve(res);
    }
  },
  mutations: {
    
  },
  modules: {
    
  }
};