import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from '@rs-911/store';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import Vuelidate from 'vuelidate';
import VueAppleSignin from '@shared/plugins/apple-sign-in';
import '@shared/registerServiceWorker';
import { WindowExtended } from '@shared/models/vue-typings/windowExtended';

const generateRandomString = (length=30)=>Math.random().toString(20).substr(2, length);
Vue.use(VueAppleSignin, {
  clientId: process.env.VUE_APP_SSO_CLIENT_ID,
  // clientId: process.env.VUE_APP_SSO_CLIENT_ID_ADMIN,
  scope: 'email',
  redirectURI: process.env.VUE_APP_SSO_REDIRECT_URL,
  state: generateRandomString(),
  usePopup: true
});

Vue.use(Vuelidate);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.config.productionTip = false;

declare const window: WindowExtended;
window.router = router;
window.vuexStore = store;
export default new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
