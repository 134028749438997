import { _axios } from '../plugins/axios';
import { Contact } from '@shared/models/contact';
import Axios from 'axios';
const API_URL = process.env.VUE_APP_RS911_URL;

class ContactService {
  getContacts() {
    return _axios.post(API_URL + '/contact/list');
  }

  createContact(contact: Contact) {
    return _axios.post(API_URL + '/contact/add', contact);
  }

  updateContact(contact: Contact) {
    return _axios.post(API_URL + '/contact/update', contact);
  }

  deleteContact(id: number) {
    return _axios.post(API_URL + '/contact/delete', {id: id});
  }

  getFavoriteContacts() {
    return _axios.post(API_URL + '/favorite/list');
  }

  createFavoriteContact(contact: Contact) {
    return _axios.post(API_URL + '/favorite/add', contact);
  }

  updateFavoriteContact(contact: Contact) {
    return _axios.post(API_URL + '/favorite/update', contact);
  }

  deleteFavoriteContact(id: number) {
    return _axios.post(API_URL + '/favorite/delete', {id: id});
  }

  qrCode(secret: string) {
    return Axios.get(API_URL + '/alert/qrcode/' + secret);
  }
}

export default new ContactService();