/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */

import { VueConstructor } from 'vue';

function err (msg: string) {
  if (typeof console !== 'undefined') {
    console.error(`[vue-apple-signin] ${msg}`);
  }
}
declare const window: any;
export default {
  install (Vue: VueConstructor, options: any): void {
    Vue.component('vue-apple-signin', {
      name: 'VueAppleSignin',
      props: {
        color: {
          type: String,
          default: 'black',
          validator (value: any) {
            return [
              'white',
              'black'
            ].indexOf(value) > -1;
          }
        },
        border: {
          type: Boolean,
          default: true
        },
        type: {
          type: String,
          default: 'sign in',
          validator (value: any) {
            return [
              'sign in',
              'sign up',
              'apple',
              'continue'
            ].indexOf(value) > -1;
          }

        }
      },
      mounted () {
        if (!window.AppleID) {
          err('"https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js" needs to be included as a <script>');
          return;
        }

        const {
          clientId,
          scope,
          redirectURI,
          state,
          usePopup
        } = options;

        if (!clientId) {
          err('options.clientId must be specified.');
          return;
        }

        if (!scope) {
          err('options.scope must be specified.');
          return;
        }

        if (!redirectURI) {
          err('options.redirectURI must be specified.');
          return;
        }

        if (!state) {
          err('options.state must be specified.');
          return;
        }

        window.AppleID.auth.init({
          clientId,
          scope,
          redirectURI,
          state,
          usePopup
        });
      },
      render (createElement: any) {
        return createElement(
          'div', {
            attrs: {
              id: 'appleid-signin',
              'data-color': this.color,
              'data-border': 'true',
              'data-type': this.type
            }
          },
        );
      }
    });
  }
};