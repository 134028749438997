// for multiple requests
let isRefreshing = false;
let failedQueue: { resolve: (value?: unknown) => void; reject: (reason?: any) => void }[] = [];
import axios from 'axios';
import { auth } from '@shared/store/auth.module';
import { WindowExtended } from '@shared/models/vue-typings/windowExtended';

declare const window: WindowExtended;
const processQueue = (error: null, token: string | null = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
      // if((window as any).router.currentRoute.name !== 'Login') {
      //   console.log('Redirect to login');
      //   (window as any).router.push('/login');
      // }
    } else {
      prom.resolve(token);
    }
  });
  
  failedQueue = [];
};

const config = {
  baseURL: process.env.VUE_APP_SSO_URL || ''
};

export const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    if(auth.state.token) {
      config.headers['Authorization'] = `Bearer ${auth.state.token}`;
    }
    
    return config;
  },
  function(error) {
    console.log('interceptError');
    // Do something with request error
    return Promise.reject(error);
  }
);

_axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  // _axios(error.config);

  const originalRequest = error.config;
  if (error.response.status === 401 && !originalRequest._retry) {
      
    if (isRefreshing) {
      return new Promise(function(resolve, reject) {
        failedQueue.push({resolve, reject});
      }).then(token => {
        originalRequest.headers['Authorization'] = 'Bearer ' + token;
        return _axios(originalRequest);
      }).catch(err => {
        return Promise.reject(err);
      });
    }

    originalRequest._retry = true;
    isRefreshing = true;

    return new Promise(function (resolve, reject) {
      window.vuexStore.dispatch('auth/refresh').then((data: { access_token: string }) => {
        originalRequest.headers['Authorization'] = 'Bearer ' + data.access_token;
        processQueue(null, data.access_token);
        resolve(axios(originalRequest));
      })
      .catch((err: any) => {
        console.log('Refresh token error');
        window.vuexStore.dispatch('auth/logout');
        reject(err);
      })
      .finally(() => { isRefreshing = false; });
      
    });
  }

  return Promise.reject(error);
});