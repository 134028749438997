import { _axios } from '@shared/plugins/axios';
const API_URL = process.env.VUE_APP_MEDINFO_URL;

class DiagnoseService {
  getUserDiagnoses() {
    return _axios.get(API_URL + '/users/diagnoses');
  }

  getUserDiagnose(id: number) {
    return _axios.get(API_URL + '/users/diagnoses/' + id);
  }

  addUserDiagnose(item: any) {
    return _axios.post(API_URL + '/users/diagnoses', item);
  }

  editUserDiagnose(id: number, physician: { name: string; userPhysicianId: number }) {
    return _axios.put(API_URL + '/users/diagnoses/' + id, physician);
  }

  deleteUserDiagnose(id: number) {
    return _axios.delete(API_URL + '/users/diagnoses/' + id);
  }
}

export default new DiagnoseService();