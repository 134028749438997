
import { _axios } from '../plugins/axios';
import { Coupon } from '@shared/models/coupon';
import { CreateSession } from '@shared/models/api/request/CreateSession';
const API_URL = process.env.VUE_APP_RSPAY_URL;

class CouponService {
  getAllCoupons() {
    return _axios.get(API_URL + '/coupons');
  }

  getAllSecondPartnerCoupons() {
    return _axios.get(API_URL + '/coupons/secondPartners');
  }

  getAllSecondPartnerCouponsById(id: number) {
    return _axios.get(API_URL + '/coupons/secondPartners/' + id);
  }

  createCoupon(coupon: Coupon) {
    return _axios.post(API_URL + '/coupons', coupon);
  }

  updateCoupon(coupon: Coupon) {
    return _axios.put(API_URL + '/coupons/' + coupon.id, coupon);
  }

  deleteCoupon(coupon: Coupon) {
    return _axios.delete(API_URL + '/coupons/' + coupon.id);
  }

  verifyCouponCode(couponCode: string) {
    return _axios.get(API_URL + '/coupons/verify?couponCode=' + couponCode);
  }

  getSubscriptions() {
    return _axios.get(API_URL + '/subscriptions');
  }

  checkSubscription(featureName: string) {
    return _axios.get(API_URL + '/subscriptions/check?featureName=' + featureName);
  }

  getProducts() {
    return _axios.get(API_URL + '/stripe/products');
  }

  checkoutSession(data: CreateSession) {
    return _axios.post(API_URL + '/stripe/session/checkout', {
      ...data,
      paymentMethodTypes: ['card'],
      successUrl: process.env.VUE_APP_WEB_URL + '/products?status=success',
      cancelUrl: process.env.VUE_APP_WEB_URL + '/products?status=error'
    });
  }

  customerPortalSession(returnUrl: string) {
    return _axios.post(API_URL + '/stripe/session/customerPortal', {returnUrl});
  }
}

export default new CouponService();