import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { Routes } from './routes';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: Routes.base,
    redirect: Routes.login
  },
  {
    path: Routes.portal,
    component: () => import('@rs-911/containers/portal/Portal.vue'),
    meta: { auth: true },
    children: [
      {
        path: '',
        name: 'Portal',
        component: () => import('@rs-911/containers/portal/PortalHome.vue'),
        meta: { auth: true }
      },
      {
        path: Routes.firstResponder,
        name: 'FirstResponder',
        component: () => import('@rs-911/containers/portal/FirstResponder.vue'),
        meta: { auth: true }
      },
      {
        path: Routes.secondPartnerInfo,
        name: 'SecondPartnerInfo',
        component: () => import('@rs-911/containers/portal/SecondPartnerInfo.vue'),
        meta: { auth: true }
      },
      {
        path: Routes.updateSecondPartner,
        name: 'UpdateSecondPartner',
        component: () => import('@rs-911/containers/portal/UpdateSecondPartner.vue'),
        meta: { auth: true }
      },
      {
        path: Routes.confirmMail,
        name: 'MailConfirmation',
        component: () => import('@rs-911/containers/portal/MailConfirmation.vue'),
        meta: { auth: false }
      },
      {
        path: Routes.backoffice,
        name: 'CodeAdmin',
        component: () => import('@rs-911/containers/portal/CouponAdmin.vue'),
        meta: { auth: true }
      },
      {
        path: Routes.secondPartnerSubs,
        name: 'SecondPartnerSubscriptions',
        component: () => import('@rs-911/containers/portal/SecondPartnerSubscriptions.vue'),
        meta: { auth: true }
      },
      {
        path: Routes.secondPartnerManagement,
        name: 'SecondPartnerManagement',
        component: () => import('@rs-911/containers/portal/SecondPartnerManagement.vue'),
        meta: { auth: true }
      },
      // {
      //   path: Routes.shop,
      //   name: 'Shop',
      //   component: () => import('@rs-store/containers/Shop.vue'),
      //   meta: { auth: true }
      // },
      {
        path: Routes.myCoupons,
        component: () => import('@rs-911/containers/portal/MyCoupons.vue'),
        meta: { auth: true }
      },
      {
        path: Routes.myInfo,
        name: 'MyInfo',
        component: () => import('@rs-911/containers/portal/MyInfo.vue'),
        meta: { auth: true }
      },
      {
        path: Routes.enterCode,
        name: 'EnterCode',
        component: () => import('@rs-911/containers/portal/EnterCode.vue'),
        meta: { auth: true }
      }
    ]
  },
  {
    path: Routes.login,
    name: 'Login',
    component: () => import('@rs-911/containers/Login.vue'),
    meta: { auth: false }
  },
  {
    path: Routes.requestResetPassword,
    name: 'RequestPasswordReset',
    component: () => import('@rs-911/containers/RequestPasswordReset.vue'),
    meta: { auth: false }
  },
  {
    path: Routes.createNewPassword,
    name: 'CreateNewPassword',
    component: () => import('@rs-911/containers/CreateNewPassword.vue'),
    meta: { auth: false }
  },
  {
    path: Routes.qrMedinfo,
    name: 'QrMedinfo',
    component: () => import('@rs-911/containers/QrMedinfo.vue'),
    meta: { auth: false }
  },
  // {
  //   path: Routes.registerUser,
  //   name: 'Register user',
  //   component: () => import('../containers/RegisterUser.vue'),
  //   meta: { auth: false }
  // },
  {
    path: Routes.secondPartnerRegistration,
    name: 'Register second parter',
    component: () => import('../containers/RegisterSecondPartner.vue'),
    meta: { auth: false }
  },
  { path: '*', component: () => import('@shared/components/NotFoundError.vue'), meta: { auth: false } }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ '../containers/About.vue')
  // }
];


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
