export class Routes {
  static base = '/';
  static main = '/main';
  static portal = '/portal';
  static portalHome = '/';
  static backoffice = 'code-administration';
  static secondPartnerSubs = 'subscriptions';
  static secondPartnerManagement = 'admin-management';
  static login = '/login';
  static register = '/register';
  static registerUser = '/user-registration';
  static secondPartnerRegistration = '/second-partner-registration'
  static updateSecondPartner = 'update-second-partner'
  static responderWelcome = '/responder-welcome';
  static firstResponder = 'first-responder';
  static secondPartnerInfo = 'partner-info';
  static confirmMail = 'confirm-mail';
  static couponAdministration = 'coupon-administration';
  static myCoupons = 'my-coupons';
  static myInfo = 'my-info';
  static enterCode = 'enter-coupon';
  static qrMedinfo = '/medinfo/qr/:secret'
  static requestResetPassword = '/reset-password'
  static createNewPassword = '/create-password'
}