import Vue from 'vue';
import Vuex from 'vuex';
import { auth } from '@shared/store/auth.module';
import { coupon } from '@shared/store/coupon.module';
import { location } from '@shared/store/location.module';
import { rs911 } from '@shared/store/rs911.module';
import { contact } from '@shared/store/contact.module';
import { medInfo } from '@shared/store/medInfo/med-info.module';
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    videoCall: false,
    disableVideoControls: false
  },
  mutations: {
    SET_VIDEO_CALL(state, value: boolean) {
      state.videoCall = value;
    },
    DISABLE_VIDEO_CONTROLS(state, value: boolean) {
      state.disableVideoControls = value;
    },
  },
  actions: {
    notify() {
     // do nothing, subscribed in App.vue
    },
    setVideoCall({commit}, value: boolean) {
      commit('SET_VIDEO_CALL', value);
    },
    disableVideoControls({commit}, value: boolean) {
      commit('DISABLE_VIDEO_CONTROLS', value);
    }
  },
  modules: {
    auth,
    coupon,
    location,
    contact,
    medInfo,
    rs911
  }
});

export default store;
