<template>
  <b-overlay :show="showOverlay" rounded="sm" style="height: 100%" opacity="1">
    <PwaInstallationPrompt />
    <template v-slot:overlay>
      <div class="text-center c-loader">
        <div class="l-wrapper">
          <img thumbnail fluid src="@rs-911/assets/logo-horizontal.svg" alt="Image 1" style="width:70%;">
          <br>
          <p style="color:black; margin-top:10px">
            Singing In Portal...
          </p>
          <br>
          <b-spinner variant="primary" label="Spinning" />
        </div>
      </div>
    </template>
    <div id="app">
      <AppHeader />
      <router-view id="router-main" @setOverlay="setOverlay" />
      <div class="c-footer">
        All Rights Reserved ©2021 RealSafe™
      </div>
    </div>
  </b-overlay>
</template>

<script>
import styles from '@shared/styles/main.scss';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import PwaInstallationPrompt from '@shared/components/PwaInstallationPrompt.vue';
import AppHeader from '@rs-911/components/AppHeader';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
		AppHeader,
    PwaInstallationPrompt
  }
})
export default class App extends Vue {
  showOverlay = false;
  loginFailed = false;
  created() {
    this.$store.dispatch('coupon/getSubscriptions');
    this.$store.dispatch('auth/getUserInfoFirstLoad');
    this.$store.subscribeAction((action, state) => {
      if(action.type === 'notify') {
        this.$bvToast.toast(action.payload.body, {
          title: action.payload.title,
          toaster: action.payload.position ? action.payload.position : 'b-toaster-bottom-right',
          variant: action.payload.variant,
          solid: true
        });
      }
    });

    document.addEventListener('AppleIDSignInOnSuccess', (data) => {
      this.showOverlay = true;
      this.$store.dispatch('auth/appleLogin', data.detail.authorization)
        .then(() =>  {
          this.$router.push('/portal');
          this.showOverlay = false;
        },
        () => this.showOverlay = false);
        //handle successful response
    });
    //Listen for authorization failures
    document.addEventListener('AppleIDSignInOnFailure', (error) => {
      console.log(error);
        //handle error.
    });
  }

  setOverlay(value) {
    this.showOverlay = value;
  }
}
</script>

