import { _axios } from '@shared/plugins/axios';
const API_URL = process.env.VUE_APP_MEDINFO_URL;

class MedicationService {
  getUserMedications() {
    return _axios.get(API_URL + '/users/medications');
  }

  getUserMedication(id: number) {
    return _axios.get(API_URL + '/users/medications/' + id);
  }

  addUserMedication(item: any) {
    return _axios.post(API_URL + '/users/medications', item);
  }

  editUserMedication(id: number, item: { name: string; dosage: string; userPhysicianId: number }) {
    return _axios.put(API_URL + '/users/medications/' + id, item);
  }

  deleteUserMedication(id: number) {
    return _axios.delete(API_URL + '/users/medications/' + id);
  }
}

export default new MedicationService();