/* eslint-disable @typescript-eslint/no-explicit-any */
import AuthService from '@shared/services/auth-service';
import { Token } from '@shared/models/token';
import { AuthState } from '@shared/models/store/authState';
import { AxiosResponse } from 'axios';
import { WindowExtended } from '@shared/models/vue-typings/windowExtended';

declare const window: WindowExtended;
const loginRoute = '/login';
const portalRoute = '/portal';

let token = JSON.parse(localStorage.getItem('access-token') || '{}');
const refreshToken = JSON.parse(localStorage.getItem('refresh-token') || '{}');
if(Object.keys(token).length === 0) {
  token = null;
}

function redirectToLogin() {
  if(window.router.currentRoute.meta?.auth) {
    window.router.push(loginRoute + '?redirect=' + window.router.currentRoute.path);
  }
}

export const auth = {
  namespaced: true,
  state: {
    token: token,
    refreshToken: refreshToken,
    name: '',
    email: '',
    emailVerified: false,
    role: '',
    loginFailure: false,
    loggedIn: false,
    ssoid: ''
  },
  actions: {
    login({ commit, dispatch }: any, user: {username: string; password: string}) {
      return AuthService.login(user.username, user.password).then(
        (token: Token) => {
          commit('loginSuccess', token);
          dispatch('getUserInfo');
          return Promise.resolve(token);
        },
        (error: any) => {
          commit('loginFailure');
          return Promise.reject(error);
        }
      );
    },
    appleLogin({ commit, dispatch }: any, data: { code: string; id_token: string; state: string}) {
      return AuthService.appleLogin(data.id_token).then(
        (token: Token) => {
          commit('loginSuccess', token);
          dispatch('getUserInfo');
          return Promise.resolve(token);
        },
        (error: any) => {
          commit('loginFailure');
          return Promise.reject(error);
        }
      );
    },
    googleLogin({ commit, dispatch }: any, googleToken: string) {
      return AuthService.googleLogin(googleToken).then(
        (token: Token) => {
          commit('loginSuccess', token);
          dispatch('getUserInfo');
          return Promise.resolve(token);
        },
        (error: any) => {
          commit('loginFailure');
          return Promise.reject(error);
        }
      );
    },
    async refresh({ commit }: any) {
      const data = await AuthService.refreshToken();
      localStorage.setItem('access-token', JSON.stringify(data.access_token));
      localStorage.setItem('refresh-token', JSON.stringify(data.refresh_token));
      commit('refresh', data);
      return Promise.resolve(data);
    },
    logout({ commit, dispatch }: any) {
      localStorage.removeItem('refresh-token');
      localStorage.removeItem('access-token');     
      commit('logout');
      dispatch('coupon/clearCouponRoles', '', { root: true });
      if(window.router.currentRoute.name !== 'Login') {
        redirectToLogin();
      }
    },
    async getUserInfo({ commit }: any) {
      try {
        const res = await AuthService.userInfo();
        commit('userInfo', res.data);
        if(window.router.currentRoute.path === loginRoute) {
          window.router.push(portalRoute);
        }
        return Promise.resolve(res.data);
      }
      catch(err) {
        commit('loginFailure');
        // redirectToLogin();
        return Promise.resolve(err);
      }     
    },
    getUserInfoFirstLoad({ commit, dispatch }: any) {
      if(token) {
        dispatch('getUserInfo');
      }
      else {
        redirectToLogin();
      }
    },
    register({ commit }: any, user: any) {
      return AuthService.register(user).then(
        (response: { data: any }) => {
          return Promise.resolve(response.data);
        },
        (error: any) => {
          return Promise.reject(error.response);
        }
      );
    },
    async createPassword({ commit }: any, data: { email: string; token: string; password: string}) {
      const response = await AuthService.createPassword(data);
      return Promise.resolve(response.data);
    },
    async requestPasswordReset({ commit }: any, email: string) {
      const response = await AuthService.requestPasswordReset(email);
      return Promise.resolve(response.data);
    },
    async getUserSecondPartner(): Promise<AxiosResponse> {
      const response = await AuthService.getUserSecondPartner();
      return Promise.resolve(response);
    },
    async registerSecondPartner({ commit }: any, user: any): Promise<AxiosResponse> {
      try {
        const response = await AuthService.registerSecondPartner(user);
        return Promise.resolve(response.data);
      } catch (error: any) {
        return Promise.reject(error.response);
      }
    },
    async updateSecondPartner({ commit }: any, user: any): Promise<AxiosResponse> {
      try {
        const response = await AuthService.updateSecondPartner(user);
        return Promise.resolve(response.data);
      } catch (error: any) {
        return Promise.reject(error.response);
      }
    },
    async getSecondPartner(): Promise<AxiosResponse> {
      try {
        const response = await AuthService.getSecondPartner();
        return Promise.resolve(response.data);
      } catch (error: any) {
        return Promise.reject(error.response);
      }
    },
    async searchSecondPartners({ commit }: any, searchString: string) {
      const response = await AuthService.searchSecondPartners(searchString);
      return Promise.resolve(response.data);
    },
    async confirmSecondPartnerMail({ commit }: any, data: { UserId: string; Token: string }) {
      try {
        const response = await AuthService.confirmSecondPartnerMail(data);
        return Promise.resolve(response.data);
      } catch (error: any) {
        return Promise.reject(error.response);
      }
    },
    async getSecondPartnerAdmins() {
      const response = await AuthService.getSecondPartnerAdmins();
      return Promise.resolve(response.data);
    },
    async grantAdmin({ commit }: any, data: {email: string; firstName: string; lastName: string }) {
      try {
        const response = await AuthService.grantAdmin(data);
        return Promise.resolve(response);
      } catch (error: any) {
        return Promise.reject(error.response);
      }
    },
    async revokeAdmin({ commit }: any, data: {email: string }) {
      const response = await AuthService.revokeAdmin(data);
      return Promise.resolve(response.data);
    }
  },
  mutations: {
    loginSuccess(state: AuthState, token: Token): void {
      state.loggedIn = true;
      state.token = token.access_token;
      state.refreshToken = token.refresh_token;
    },
    userInfo(state: AuthState, data: any): void {
      state.role = data.role;
      state.name = data.email;
      state.email = data.email;
      state.ssoid = data.sub;
      state.emailVerified = data.email_verified;
      state.loggedIn = true;
    },
    loginFailure(state: AuthState): void {
      state.loginFailure = true;
      state.token = '';
    },
    refresh(state: AuthState, token: Token): void {
      state.token = token.access_token;
      state.refreshToken = token.refresh_token;
    },
    logout(state: AuthState): void {
      state.loggedIn = false;
      state.token = '';
      state.name = '';
      state.refreshToken = '';
      state.emailVerified = false;
      state.email = '';
      state.role = '';
    },
    registerSuccess(state: { status: { loggedIn: boolean } }): void {
      state.status.loggedIn = false;
    },
    registerFailure(state: { status: { loggedIn: boolean } }): void {
      state.status.loggedIn = false;
    }
  }
};

