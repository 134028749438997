




































import { Component, Vue } from 'vue-property-decorator';
import { Routes } from '@rs-911/router/routes';

@Component
export default class AppHeader extends Vue {
  routes = Routes;
  logOut() {
    this.$store.dispatch('auth/logout');
  }

  resetCallId() {
    this.$store.commit('rs911/clearCallId');
  }
  
  get getCurrentRoute() {
    return this.$route.name;
  }
}
