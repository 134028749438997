

































import cookie from 'js-cookie';
import { Component, Vue } from 'vue-property-decorator';
type InstallEvent = { prompt: () => void };

@Component
export default class PwaInstallationPrompt extends Vue {
  deferredPrompt: InstallEvent | null = null;
  showBottom = false;
  newAppDate = new Date();

  created(): void {
    window.addEventListener('beforeinstallprompt', (e: any) => {
      e.preventDefault();
      if(cookie.get('install-prompt') === undefined) {
        this.deferredPrompt = e;
      }
    });

    window.addEventListener('appinstalled', () => {
      this.deferredPrompt = null;
    });

    window.addEventListener('newPwaContent', () => {
      this.newAppDate = new Date();
      this.$bvToast.show('pwa-refresh-toast');
    });
  }

  install(): void {
    this.deferredPrompt?.prompt();
  }
  dismiss(): void {
    cookie.set('install-prompt', '', { expires: 10 });
    this.deferredPrompt = null;
  }
}
