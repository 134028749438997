import { _axios } from '../plugins/axios';
const API_URL = process.env.VUE_APP_RS911_URL;

class Rs911Service {
  getUser() {
    return _axios.post(API_URL + '/user/get');
  }

  isFirstInstall() {
    return _axios.get(API_URL + '/user/isfirstinstall');
  }

  updateUser(user: any) {
    return _axios.post(API_URL + '/user/update', user);
  }

  searchFr(orgid: number, keyword: string) {
    return _axios.post(API_URL + '/user/search', { orgid: orgid, keyword: keyword });
  //   return _axios.post(API_URL + '/user/search', {
  //     'orgid': '12',
  //     'keyword': 'Ja'
  // });
  }

  getLocation(id: string) {
    return _axios.post(API_URL + '/alert/location', { callid: id });
  }

  getContacts(id: string) {
    return _axios.post(API_URL + '/alert/contacts', { callid: id });
  }

  getMedinfo(id: string) {
    return _axios.post(API_URL + '/alert/medinfo', { callid: id });
  }

  getAlert(id: string) {
    return _axios.post(API_URL + '/alert/respond', { callid: id });
  }

  invite(payload: { users: any; callid: string }) {
    return _axios.post(API_URL + '/alert/invite', payload);
  }

  qrcode() {
    return _axios.get(API_URL + '/alert/qrcode', { headers: {
      'content-type': 'image/png',
      'accept': 'image/png'
      }, responseType: 'blob'
    });
  }

  getVideoToken(id: string) {
    return _axios.post(API_URL + '/user/videotoken', { room_name: id });
  }

}

export default new Rs911Service();